.modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 100;
}

.modal-content {
  position: relative;
  background: #fff;
  border: 2px solid #aaa;
  border-radius: 5px;
  z-index: 200;
  min-width: 500px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: visible;
  overflow-x: visible;
  margin: auto;
  padding: 30px;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: #000;
  opacity: 0.7;
}
